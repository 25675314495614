<template>
  <div>
    <div class="d-flex align-items-baseline flex-row mb-3">
      <label class="mr-3">Nome</label>
      <b-form-input
        v-model="pacienteFiltro"
        @keydown.enter="buscar"
      ></b-form-input>
      <b-btn
        :disabled="loading"
        variant="transparent"
        class="ml-1"
        @click="buscar"
      >
        <i class="fas fa-search"></i
      ></b-btn>
    </div>

    <!-- <strong v-if="loading"> <b-icon animation="cylon" icon="search"></b-icon> Buscando...</strong> -->
    <b-table
      stacked="sm"
      :fields="campos"
      :filter="pacienteFiltro ? pacienteFiltro : ''"
      :items="lista"
      :busy="loading"
      show-empty
      small
      selectable
      hover
      select-mode="single"
      ref="tableConsultas"
      @row-selected="onRowSelected($event)"
      primary-key="id"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <!-- <b-spinner small class="align-middle"></b-spinner> -->
          <strong>
            <b-icon animation="cylon" icon="search"></b-icon>
            Buscando...</strong
          >
        </div>
      </template>
      <template #empty>
        <h5 class="text-center">🔍 Busque a consulta...</h5>
      </template>

      <template #emptyfiltered>
        <p class="text-center">
          Nenhum resultado para <em>{{ pacienteFiltro }}</em
          >.
        </p>
      </template>

      <template #cell(data)="row">
        {{ row.item.data | moment("DD/MM/YYYY") }}
      </template>

      <template #cell(fornecedor)="row">
        {{ row.item.fornecedor }}<br />
        <small>
          {{ row.item.descricao }}
        </small>
      </template>

      <template #cell(retorno)="row">
        <b-form-checkbox
          disabled
          v-model="row.item.retorno"
          :unchecked-value="0"
          :value="1"
        ></b-form-checkbox>
      </template>

      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Selected</span>
        </template>
      </template>
    </b-table>
  </div>
</template>

<script>
// import HorariosLib from "../../libs/HorariosLib";
import VendaLib from "../../libs/VendaLib";
import moment from "moment";

export default {
  props: {
    pacienteFiltroProp: { type: String, default: "" },
    dataAgenda: { type: String, default: "" },
    soConsultas:{type: Boolean, default: false }
  },
  data() {
    return {
      lista: [],
      campos: [
        { key: "selected", label: "", class: "text-center" },
        {
          key: "data",
          label: "Data",
          sortable: true,
          class: "text-center"
        },
        { key: "clinica", label: "Clínica", class: "text-center" },
        { key: "paciente", label: "Paciente", class: "text-left" },
        { key: "fornecedor", label: "Médico", class: "text-left" },
        { key: "retorno", label: "Retorno", class: "text-center" }
      ],
      paginacao: {
        registros_por_pagina: 10,
        pagina_atual: 1
      },
      pacienteFiltro: "",
      loading: false,
      horarioSelected: []
    };
  },
  methods: {
    async buscar() {
      try {
        this.loading = true;
        // this.lista = await HorariosLib.get(
        //   "",
        //   this.pacienteFiltro,
        //   moment(this.dataAgenda).format("YYYY-MM-DD")
        // );
        let r = await VendaLib.buscarVendas({
          nome: this.pacienteFiltro,
          limit: 3
        });
        console.log('res',r);
        this.lista = r
          .reduce((ret, vl) => {
            let op = Object.assign({}, vl);
            delete op.itens;
            delete op.pagamentos;

            for (let it of vl.itens.filter(x => x.item_nivel == 1 && x.tipo_exame==="Consulta")) {
              ret.push({ ...it, ...op });
            }

            return ret;
          }, [])
          .sort((a, b) => {
            return moment(a.data).isAfter(b.data) ? -1 : 1;
          });

        /*for (let i of r) {
          this.lista.push({ ...i });
          //for (let it of i.itens) {
            //this.lista.push({ ...i, ...it });
          //}
        }*/
        /*this.lista = this.lista.sort((a, b) => {
          return moment(a.data).isAfter(b.data) ? -1 : 1;
        })*/
        console.log(this.lista);
      } catch (error) {
        this.showToast("error", "Erro ao buscar consulta" + error.message);
      } finally {
        this.loading = false;
      }
    },
    onRowSelected(horario) {
      let msgErrors = [];
      if (horario && horario[0].usado === 1) {
        msgErrors.push("Já há um retorno referente à essa consulta");
      }
      if (horario && horario[0].retorno) {
        msgErrors.push("Você não pode marcar retorno de outro retorno.\n");
      }

      if (msgErrors.length > 0) {
        this.$refs.tableConsultas.clearSelected();
        this.showToast("error", msgErrors, 7000);
        return;
      }

      this.horarioSelected = horario;
    },
    salvar() {
      if (this.horarioSelected.length === 0) {
        this.showToast("error", "Selecione uma consulta.");
        return;
      }

      this.$emit("consultaSelecionada", this.horarioSelected[0]);
    },
    responsabilizar() {
      this.toastConfirmacao(
        () => {
          this.$emit("responsabilizou", this.$store.state.auth.user.id);
        },
        () => {},
        "Atenção!",
        "Tem certeza que deseja se responsabilizar por este retorno?",
        "question",
        "Sim, me responsabilizar!"
      );
    }
  },
  //   selecionaPaciente(items){
  //       console.log('lksjdfjd')

  //       this.paciente = items
  //   },
  mounted() {
    this.$forceUpdate();
    if (this.pacienteFiltroProp) {
      this.pacienteFiltro = this.pacienteFiltroProp;
      this.buscar();
    }
  }
};
</script>

<style>
</style>