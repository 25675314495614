<template>
  <div>
    <div v-if="loading">
      <h3 class="text-center"><b-spinner></b-spinner> Carregando...</h3>
    </div>
    <EmptyState v-if="(!historico || historico.length == 0) && !loading">
      <h3>Nada encontrado!</h3>
      <p>O paciente ainda não tem nada no histórico...</p>
    </EmptyState>
    <b-table
      style="font-size: 10px"
      sticky-header="500px"
      responsive="sm"
      outlined
      shadow-sm
      :fields="campos"
      v-if="!loading && historico && historico.length"
      :items="historico"
      small
    >
      <template #head(data)="{ label }">
        <div class="text-nowrap">
          <i class="fas fa-calendar-alt"></i> {{ label }}
        </div>
      </template>
      <template #head(clinica)="{ label }">
        <div class="text-nowrap">
          <i class="fas fa-clinic-medical"></i> {{ label }}
        </div>
      </template>
      <template #head(medico)="{ label }">
        <div class="text-nowrap">
          <i class="fas fa-user-nurse"></i> {{ label }}
        </div>
      </template>
      <template #head(usuario)="{ label }">
        <div class="text-nowrap"><i class="fas fa-user"></i> {{ label }}</div>
      </template>
      <template #cell(tipo)="{ item }">
        <div class="iconTipo">
          <img :src="icons[item.tipo]" />
          {{ item.tipo }}
        </div>
      </template>
      <template #cell(data)="{ item }">
        <div class="">
          {{ item.data | moment("DD/MM/YYYY") }}
        </div>
      </template>
      <template #cell(medico)="{ item }">        
        <div v-if="item.autor">
          {{ item.autor.split(',')[0] }}
        </div>
        <div v-if="!item.autor">
          <small class="text-muted"> Nenhum médico encontrado... </small>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import EntidadesLib from "../../../libs/EntidadesLib";

import anamneseImg from "../../../assets/img/icones/menu/icons8_treatment_40px.png";
import fidelizacaoImg from "../../../assets/img/icones/menu/loyalty_40px.png";
import receitaImg from "../../../assets/img/icones/menu/icons8_autograph_40px.png";
import exameImg from "../../../assets/img/icones/menu/icons8_heart_with_pulse_40px.png";
import caixaImg from "../../../assets/img/icones/menu/cash_register_40px.png";
import horarioImg from "../../../assets/img/icones/menu/today_52px.png";
import atestadoImg from "../../../assets/img/icones/menu/icons8_user_clock_40px.png";
import EmptyState from "../../common/EmptyState.vue";

export default {
  components: { EmptyState },
  props: {
    paciente_id: [String, Number],
    nome: String,
  },
  async created() {
    await this.carregar();
  },
  data() {
    return {
      loading: false,
      historico: [],
      icons: {
        Anamnese: anamneseImg,
        Fidelizacao: fidelizacaoImg,
        Receita: receitaImg,
        Exame: exameImg,
        Venda: caixaImg,
        Horario: horarioImg,
        Atestado: atestadoImg,
      },
      campos: [
        { key: "tipo", label: "", class: "text-center" },
        { key: "data", label: " Data", class: "align-middle" },
        { key: "clinica", label: "Clinica" },
        { key: "medico", label: "Médico" },
        { key: "usuario", label: "Usuario" },
        // { key: "EntidadeNome", label: "Médico", sortable: true },
        // { key: "clinic", label: "Empresa" },
        // { key: "actions", label: "" },
      ],
    };
  },
  watch: {
    paciente_id: function () {
      this.carregar();
    },
    nome: function () {
      this.carregar();
    },
  },
  computed: {},
  methods: {
    async carregar() {
      // console.log("buscando historico inside", this.nome, this.paciente_id);
      this.loading = true;
      this.historico = [];
      if (this.paciente_id) {
        try {
          this.historico = (await EntidadesLib.getPacienteHistorico({
            paciente_id: this.paciente_id,
            nome: this.nome,
          })).filter(x=>x.tipo == "Venda");
          console.log("hist", this.historico)
        } catch (error) {
          this.showToast("error", error.message, 3500);
        }
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.iconTipo {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    filter: invert(1);
    width: 20px;
  }
}
</style>
